import React from "react";
import "../Styles/styles.css";
import "bootstrap/dist/css/bootstrap.min.css";

export const ErrorPage = () => {
  return (
    <div className="App">
      <h1>404 Error</h1>
      <h1>Oops! Page Not Found</h1>
    </div>
  );
};
